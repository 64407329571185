













import { Component, Vue } from 'vue-property-decorator';
import merge from 'lodash.merge';
import { darkTheme } from '@/plugins/highcharts';

interface Options {
  chart: { type: string };
  title: { text: string };
  tooltip: { crosshairs: boolean, shared: boolean };
  plotOptions: { line: { dataLabels: { enabled: boolean } } };
  xAxis: { categories: string[], title: { text: string } };
  yAxis: { min: number, title: { text: string } };
  series: Array<{ name: string, data: { y: number, formatted: string } }>;
  colors: string[];
}

@Component
export default class Conservatorship extends Vue {
  waiting: boolean = false;

  options: Options = {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Statistik: Betreuungen'
    },
    tooltip: {
      crosshairs: true,
      shared: true
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        }
      }
    },
    xAxis: {
      categories: [],
      title: {
        text: 'Jahr'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Anzahl Betreuungen'
      }
    },
    series: [],
    colors: ['#b71c1c', '#92c3ff', '#ff9c43', '#3fdd7c', '#9b1fdd', '#d97bdd']
  };

  get allOptions() {
    if (!this.$vuetify.theme.dark) {
      return this.options;
    }

    return merge(darkTheme(), this.options)
  }

  async created() {
    const response = await this.$http.get('api/statistic/conservatorship', {
      responseType: 'json',
      params: { start: 2000 }
    });

    this.options.xAxis.categories = response.data['years'];
    this.options.series.push({ name: 'Summe Betreuungen', data: response.data['sum'] });
    this.options.series.push({ name: 'Summe neue Betreuungen', data: response.data['open'] });
    this.options.series.push({ name: 'Neue Betreuungen', data: response.data['new'] });
    this.options.series.push({ name: 'Übernommene Betreuungen', data: response.data['assigned'] });
    this.options.series.push({ name: 'Beendete Betreuungen', data: response.data['closed'] });

    setTimeout(() => {
      this.waiting = true;
    }, 500);
  }
}
